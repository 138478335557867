import React, { useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as SideMenuArrow } from "../../assets/icons/sidebar/ExpandMenuArrow.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/sidebar/SettingsIcon.svg";
import { ReactComponent as LinksIcon } from "../../assets/icons/sidebar/Link.svg";
import { ReactComponent as IntegrationsIcon } from "../../assets/icons/sidebar/IntegrationIcon.svg";
import { ReactComponent as MembersIcon } from "../../assets/icons/sidebar/Members.svg";
import { ReactComponent as LogoIcon } from "../../assets/icons/generic/small-svg-icon-for-menu.svg";
import { ReactComponent as LogoLong } from "../../assets/icons/generic/logo-expanded-for-menu.svg";
import { ReactComponent as Subscriptions } from "../../assets/icons/sidebar/PlanOverviewIcon.svg";
import { ReactComponent as Notifications } from "../../assets/icons/sidebar/notificationMenuIcon.svg";
import { ReactComponent as Configuration } from "../../assets/icons/sidebar/ConfigurationIcon.svg";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  AUDIENCE,
  CONFIGURATION,
  DEVELOPERS,
  LINKS,
  MESSAGES,
  NOTIFICATIONS,
  SETTING,
} from "../../constants/OptionsConstants";
import ProjectsMenu from "../createNewProject/ProjectsMenu";
import { useExpanded } from "../../hooks/useExpanded";
import NewProjectSideBar from "./NewProjectSideBar";
import { AnimatePresence, motion } from "framer-motion";

export const MenuItem = ({
  expanded,
  icon,
  onClick,
  text,
  style,
  selected,
  logo,
}) => {
  return (
    <ItemContainer
      onClick={onClick}
      expanded={expanded}
      selected={selected}
      style={style}
    >
      {icon && (
        <IconWrapper selected={selected} expanded={expanded}>
          {icon}
        </IconWrapper>
      )}

      {text && (
        <ItemTextContainer selected={selected}>
          {expanded && <p>{text}</p>}
        </ItemTextContainer>
      )}
    </ItemContainer>
  );
};

const SideBarMenu = ({ setCreateNewProjectModalOpen }) => {
  const [expanded, expand] = useExpanded(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [projectMenuExpanded, expandProjectMenu] = useExpanded(false);
  const navigate = useNavigate();

  const ref = useRef();

  const goTo = (path) => {
    navigate({
      pathname: path,
      search: createSearchParams(searchParams).toString(),
    });
  };

  const MENU_OPTIONS = [
    {
      option: DEVELOPERS,
      icon: <IntegrationsIcon />,
      link: "/developers",
      action: () => goTo("/developers"),
    },
    {
      option: SETTING,
      icon: <SettingsIcon />,
      link: "/settings",
      action: () => goTo("/settings/subscription"),
    },
  ];

  const SETTINGS_OPTIONS = [
    {
      option: LINKS,
      icon: <LinksIcon />,
      link: "/links",
      action: () => goTo("/links"),
    },
    {
      option: AUDIENCE,
      icon: <MembersIcon />,
      link: "/audience",
      action: () => goTo("/audience"),
    },

    {
      option: CONFIGURATION,
      icon: <Configuration />,
      link: "/configuration",
      action: () => goTo("/configuration"),
    },
    {
      option: MESSAGES,
      icon: <Notifications />,
      link: "/notifications",
      action: () => goTo("/notifications"),
    },
  ];

  return (
    <AnimatePresence>
      <Container
        ref={ref}
        as={motion.div}
        animate={expanded ? { width: "250px" } : { width: "60px" }}
        transition={{ duration: 0.2 }}
      >
        <AnimatePresence>
          {projectMenuExpanded && (
            <NewProjectSideBar
              containerRef={ref}
              expandProjectMenu={expandProjectMenu}
              setCreateNewProjectModalOpen={setCreateNewProjectModalOpen}
              isModalOpen={projectMenuExpanded}
            />
          )}
        </AnimatePresence>

        {expanded ? (
          <SidebarLogoWrapper expanded={expanded}>
            <LogoLong />
          </SidebarLogoWrapper>
        ) : (
          <SidebarLogoWrapper expanded={expanded}>
            <LogoIcon />
          </SidebarLogoWrapper>
        )}

        <Content>
          <List expanded={expanded}>
            <ProjectsMenu
              menuExpanded={expanded}
              expandProjectMenu={expandProjectMenu}
              projectMenuExpanded={projectMenuExpanded}
            />
          </List>
          <Separator />
          <List expanded={expanded}>
            {SETTINGS_OPTIONS.map((item, index) => (
              <MenuItem
                key={index}
                onClick={item.action}
                expanded={expanded}
                icon={item.icon}
                text={item.option}
                selected={window.location.pathname === item.link}
              />
            ))}
          </List>
          <BottomSection>
            <Separator />
            <List expanded={expanded}>
              {MENU_OPTIONS.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={item.action}
                  expanded={expanded}
                  icon={item.icon}
                  text={item.option}
                  selected={window.location.pathname.includes(item.link)}
                />
              ))}
            </List>
          </BottomSection>
        </Content>
        <Separator />
        <MenuBottom>
          <MenuItem
            style={{ background: "none", paddingRight: "20px" }}
            onClick={() => expand()}
            expanded={expanded}
            icon={
              <SideMenuArrow
                style={
                  expanded
                    ? {
                        transform: "scaleX(-1)",
                        transition: "200ms linear",
                        height: "15px",
                      }
                    : { height: "15px" }
                }
              />
            }
          />
        </MenuBottom>
      </Container>
    </AnimatePresence>
  );
};

export default SideBarMenu;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.menuBackground};
  align-items: center;
  height: 100%;
  position: relative;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  min-height: 40px;
  cursor: pointer;
  padding-left: ${(props) => (props.expanded ? "10px" : "0px")};
  gap: 5px;
  background: ${(props) =>
    props.expanded && !props.logo
      ? props.selected
        ? lightThemeColors.sidebar.selectedItemBg
        : ""
      : props.selected
      ? lightThemeColors.sidebar.selectedItemBg
      : ""};
  transition: 200ms linear;
  border-radius: 5px;
  position: relative;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  min-height: 40px;
  max-width: 30px;
  max-height: 40px;
  position: relative;
  transition: 200ms linear;
  background: ${(props) =>
    props.selected && !props.expanded
      ? lightThemeColors.sidebar.primaryColor
      : ""};
  margin-left: auto;
  border-radius: 5px;
  padding-left: ${(props) => (props.expanded ? "0px" : "12px")};
  svg {
    position: relative;
    height: 16px;
    width: 16px;
    object-fit: contain;
    color: ${(props) =>
      props.selected
        ? lightThemeColors.sidebar.selectedItemAccent
        : lightThemeColors.darkColorFaded};
  }
`;

const ItemTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 5px;
  width: 100%;
  overflow: hidden;
  p {
    font-weight: ${(props) => (props.logo ? "700" : "500")};
    font-size: 15px;
    display: flex;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    color: ${(props) =>
      props.selected
        ? lightThemeColors.sidebar.selectedItemAccent
        : lightThemeColors.darkColorFaded};
  }
`;

const BottomSection = styled.div`
  margin-top: auto;
  width: 100%;
`;

const MenuBottom = styled.div`
  overflow: hidden;
  width: 100%;
  min-height: 40px;
`;
const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.expanded ? "30px 0" : "30px 0")};
  width: 100%;
  gap: 10px;
  position: relative;
  transition: 400ms linear;
`;
const Separator = styled.div`
  display: flex;
  width: 100%;
  height: 0.5px;
  min-height: 0.5px;
  background: rgba(151, 168, 190, 0.5);
`;

const SidebarLogoWrapper = styled.div`
  margin-left: ${(props) => (props.expanded ? "20px" : "auto")};
  margin-right: auto;
  padding: 30px 10px;
  svg {
    width: 100%;
    max-height: ${(props) => (props.expanded ? "30px" : "30px")};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 0 10px;
`;
